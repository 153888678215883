import { useMemo } from 'react';
import config from '../config';

const keyVisual1 = '/images/keyvisuals/keyvisual_large_1.jpg';
const keyVisual2 = '/images/keyvisuals/keyvisual_large_2.jpg';
const keyVisual3 = '/images/keyvisuals/keyvisual_large_3.jpg';
const keyVisual4 = '/images/keyvisuals/keyvisual_large_4.jpg';

const keyVisuals = [
  keyVisual1,
  keyVisual2,
  keyVisual3,
  keyVisual4
];

export const useImageBuilder = () => {

    return useMemo(() => {
        const buildImageUrl = (
            file?: any,
            width: number | null | undefined = null,
            height: number | null | undefined = null,
        ): string => {
            if (!file) return '';
            return new URL(`/asset/image/${file.file_id}/${width ? Math.round(width) : ''}x${height ? Math.round(height) : ''}`, config.apiHost).toString();
        };

        const loadImage = async (srcStr: any) => {
            if (srcStr instanceof File) {
                return srcStr;
            } else {
                return await fetch(srcStr, { mode: 'no-cors' }).then(r =>
                    r.blob(),
                );
            }
        };


        const buildFromAttachments = (
          attachments: Array<any>,
          name: string,
          width: number | null | undefined = null,
          height: number | null | undefined = null,
        ) => {
            for (const attachment of attachments) {
                if (attachment.content_name === name) {
                    return buildImageUrl(attachment, width, height);
                }
            }
            return undefined;
        }

        const getRandomKeyVisual = () => {
            const imageId = Math.floor(Math.random() * 4);
            return keyVisuals[imageId];
        };

        return {
            loadImage,
            buildImageUrl,
            buildFromAttachments,
            getRandomKeyVisual,
        };
    }, []);
};
