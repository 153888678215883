import React from "react";
import { RouteObject } from "react-router";

import App from "../App";
import MainRoutes from './MainRoutes';
import PreviewRoutes from "./PreviewRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export const routes: Array<RouteObject> = [
    {
        path: '*',
        element: <App />,
        children: [MainRoutes, PreviewRoutes],
    }
];
