import React from "react";

import { BlockProps } from "./blocks";
import DataBinding from "../DataBinding";
import { useImageBuilder } from "../../utils/image";
import { useTranslation } from "../../context/translation";
import { useDateFormatter } from "../../utils/date";
import BlurryLoadingImage from "../BlurryLoadingImage";
import { useFormatter } from "../../utils/static";

interface PartnerHeadBlockProps extends BlockProps {
  headline?: string;
  text?: string;
}

export default function PartnerHeadBlock({ headline, text, page }: PartnerHeadBlockProps) {
  const { translate } = useTranslation();
  const { buildFromAttachments, getRandomKeyVisual } = useImageBuilder();
  const { formatDate } = useDateFormatter();
  const { formatNumber } = useFormatter();

  const additionalWording = page?.bonded_data?.additional_wording ?? false;
  const pointType = page?.bonded_data?.min_refund?.point_type ?? "factor";
  const amount = page?.bonded_data?.min_refund?.point_value ?? 1;

  const startDate = page?.bonded_data?.min_refund?.start_date;
  const endDate = page?.bonded_data?.min_refund?.end_date;

  const attachments = page?.bonded_data?.attachments ?? [];

  const partnerTermText = page?.bonded_data?.terms ?? null;

  const freeShipping = page?.bonded_data?.has_free_shipping ?? false;

  const searchByLocale = (data: any) => {
    for (const item of data) {
      if (item.locale === page.locale.substring(0, 2)) {
        return item.content;
      }
    }
    return "";
  };

  const renderHint = (hint: any, hintIndex: number) => {
    const textContent = searchByLocale(hint.title).split(/<\/?p\s*\/?>|<br\s+class=["']softbreak["']\s*\/?>/i);

    const renderText = (text: string, textIndex: number) => {
      if (text.length > 0) {
        return (
          <p key={`hint-${hintIndex}-text-${textIndex}`} className="hint_info">
            {hint.type === "reduction" ? (
              <span className="mam-ico90"></span>
            ) : (
              <span className="mam-ico13"></span>
            )}
            <span className="hint_info_text" dangerouslySetInnerHTML={{ __html: text }} />
          </p>
        );
      }
    };
    return (
      <div key={`hint-${hintIndex}`}>
        {hint?.title?.length > 0 && textContent.map(renderText)}
        {hint?.terms?.length > 0 && (
          <p className="terms_info">
            <span className="mam-ico94"></span>
            <span className="terms_info_text" dangerouslySetInnerHTML={{ __html: searchByLocale(hint.terms) }} />
          </p>
        )}
      </div>
    );
  };

  const openExitModal = () => {
    document.dispatchEvent(new CustomEvent('openExitModal'));
  };

  let partnerLogo = buildFromAttachments(attachments, "Logo", 202, 128);
  let keyVisualPreview = buildFromAttachments(attachments, "KeyVisual", 180, 73);
  let keyVisual = buildFromAttachments(attachments, "KeyVisual", 2560, 1024);
  if (!keyVisual) {
    keyVisual = getRandomKeyVisual();
  }
  if (!keyVisualPreview) {
    keyVisualPreview = getRandomKeyVisual();
  }

  const filteredHints = (page?.bonded_data?.currently_active_hints ?? []).filter((item: any) => item.type !== "campaign");

  return (
    <>
      <section className="mamcom_component mam_offer_info">
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <div className="heading_base">
              <div className="heading">
                <header className="heading_wrapper">
                  <div className="heading_logo col-xs-12 col-md-3 left">
                    {partnerLogo ? (
                      <img src={partnerLogo} alt={`Miles &amp; More Partner ${page?.bonded_data?.name}`} />
                    ) : (
                      <h3 className="heading_text">
                        <p><strong>Miles &amp; More</strong></p>
                      </h3>
                    )}
                  </div>
                  <div className="heading_content col-xs-12 col-md-9 right">
                    <h1 className="heading_text">
                      <DataBinding text={headline ?? ""} data={page} />
                    </h1>
                  </div>
                </header>
              </div>
            </div>
          </div>
          <div className="keyvisual col-xs-12">
            <div className="keyvisual_container">
              <BlurryLoadingImage preview={keyVisualPreview} image={keyVisual} alt={page?.bonded_data?.name} />
              {amount !== 1 && (
                <div
                  className="flag">
                  {
                    additionalWording ?
                      translate(`mam.miles.additional_wording.${pointType}`, formatNumber(amount)) :
                      translate(`mam.blocks.partner.head.miles_${pointType}`, formatNumber(amount))
                  }
                </div>
              )}
            </div>
          </div>
          <div className="infobox col-sm-12">
            <div className="infobox_container col-xs-12 col-md-5 offset-md-1 col-lg-5">
              <div className="infobox_content">
                <h4>{translate("mam.blocks.partner.head.offer_title")}</h4>
                <p className="miles_info">
                  <span className="mam-ico13"></span>
                  <span
                    className="miles_info_text"
                  >
                    <span
                      key="miles">
                      {
                        additionalWording ?
                          translate(`mam.miles.additional_wording.${pointType}`, formatNumber(amount)) :
                          translate(`mam.blocks.partner.head.miles_${pointType}`, formatNumber(amount))
                      }
                    </span><br />
                    {(startDate && endDate) && (
                      <span key="date">
                      {translate("mam.blocks.partner.head.miles_date_from_to", formatDate(startDate), formatDate(endDate))}
                    </span>
                    )}
                </span>
                </p>
                {filteredHints.map(renderHint)}
                {freeShipping && (
                  <p className="hint_info">
                    <span className="mam-ico99"></span>
                    <span className="hint_info_text">{translate("mam.blocks.partner.head.free_shipping")}</span>
                  </p>
                )}
                {partnerTermText && (
                  <p className="terms_info">
                    <span className="mam-ico94"></span>
                    <span className="terms_info_text">{partnerTermText}</span>
                  </p>
                )}
                <div className="shop_button">
                  <button className="shop_redirect_button button button--filled button--primary" onClick={openExitModal} data-role="none">
                    <span className="button_text">{translate("mam.blocks.partner.head.earn_btn")}</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="specialterms_container col-xs-12 col-md-5 col-lg-5">
              <DataBinding text={text ?? ""} data={page} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
