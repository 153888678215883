import { ApiClass, ResponseType } from "./types";

const knownStatus = [200, 201, 400, 401, 403, 404, 500];


export type TranslationValues = {
  [key: string]: string;
};

export type Translation = {
  getTranslation: (slug: string, locale: string) => Promise<ResponseType<TranslationValues> | null>;
};

const translation = (
  apiHost: string,
  token: string | null,
  onNetworkError: () => void = () => {},
): ApiClass<Translation> => ({
  getTranslation: async (locale: string): Promise<TranslationValues | null> => {
    const translationHeaders: Headers = new Headers({
      "Content-Type": "application/json"
    });

    if (token) {
      translationHeaders.set(
        "Authorization",
        `Bearer ${token}`
      );
    }

    try {
      const response = await fetch(
        new URL(`/${locale}/translation`, apiHost),
        {
          method: "GET",
          headers: translationHeaders,
          mode: "cors"
        }
      );
      return await response.json();
    } catch (e) {
      onNetworkError();
      return null;
    }
  },
});

export default translation;
