import React from "react";

type HeadlineProps = {
  headline: string;
};

export default function Headline({headline}: HeadlineProps) {

  return (
    <section className="mamcomcomponent mam_heading_container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <div className="heading_base">
            <div className="heading">
              <header className="heading_wrapper">
                <div className="heading_content">
                  <h1 className="heading_text small" dangerouslySetInnerHTML={{__html: headline}} />
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
