import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";

import localTranslations from "../translations.local";
import { useDataQuery } from "../utils/data-query";

export type TranslationContextType = {
  i18n: string;
  translate: (key: string, ...params: any[]) => string;
};

export const DefaultTranslationContextValue: TranslationContextType = {
  i18n: 'de-DE',
  translate: (key: string, ...params: any[]) => '',
};

export const TRANSLATION_CONTEXT = createContext(DefaultTranslationContextValue);

export const useTranslation = () => {
  return useContext(TRANSLATION_CONTEXT);
};

type TranslationContextProviderProps = {
  i18n: string;
  children: ReactElement;
};

export default function TranslationContextProvider({children, i18n}: TranslationContextProviderProps) {
  const { initialized, data, runQuery } = useDataQuery();

  const loadTranslation = () => {
    if (i18n && initialized) {
      runQuery({
        api: "translation",
        call: "getTranslation",
        args: [i18n]
      }, false);
    }
  };

  useEffect(loadTranslation, [i18n, initialized]);

  const translate = useCallback((key: string, ...params: any[]) => {
    if (!initialized) {
      return null;
    }
    if (data && data[key]) {
      let val = data[key];
      for (const parameter of params) {
        val = val.replace('%s', parameter + '');
      }
      return val;
    }
    const localTransLocale = i18n.substring(0, 2);
    if (
      localTranslations &&
      localTranslations[localTransLocale] &&
      localTranslations[localTransLocale][key]
    ) {
      let val = localTranslations[localTransLocale][key];
      for (const parameter of params) {
        val = val.replace('%s', parameter + '');
      }
      return val;
    }
    return `${key} ${params.join(' ')}`;
  }, [data]);

  const value = {
    i18n,
    translate,
  };

  return useMemo(() => {
    return (
      <TRANSLATION_CONTEXT.Provider value={value}>
        {children}
      </TRANSLATION_CONTEXT.Provider>
    )
  }, [translate]);
}
