import { ApiClass, ResponseType } from "./types";

const knownStatus = [200, 201, 400, 401, 403, 404, 500];


type Page = {
    title: string;
    slug: string;
    last_edited: string;
    online: boolean;
    blocks: Array<any>;
}


export type Cms = {
    runApiRouter: (slug: string, locale: string) => Promise<ResponseType<Page> | null>;
};

const cms = (
    apiHost: string,
    token: string | null,
    onNetworkError: () => void,
    locale?: string
): ApiClass<Cms> => ({
    runApiRouter: async (slug: string): Promise<ResponseType<Page> | null> => {
        try {
            const headers: any = {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            if (locale) {
                headers["Accept-Language"] = locale;
            }
            const response = await fetch(
                new URL(
                    `/page/${slug}`,
                    apiHost,
                ),
                {
                    method: 'GET',
                    headers,
                    mode: 'cors',
                },
            );
            return response.json();
        } catch (e) {
            onNetworkError();
            return null;
        }
    },
});

export default cms;
