import { useTranslation } from "../../context/translation";
import { useDataQuery } from "../../utils/data-query";
import { useEffect } from "react";
import { PageListItem } from "../index";
import { useLinkBuilder } from "../../utils/link";


type PartnerCategoriesProps = {
  title?: string;
  subTitle?: string;
  searchPage?: PageListItem;
};

export default function PartnerCategories({title, subTitle, searchPage}: PartnerCategoriesProps) {
  const {translate} = useTranslation();
  const {buildLinkByPage} = useLinkBuilder();

  const {data, runQuery} = useDataQuery();

  useEffect(() => {
    runQuery({
      api: "partner",
      call: "getCategoryList",
      args: [1000, 0, "translationKey", "asc"]
    }, true);
  }, []);

  const renderCategory = (item: any, index: number) => {
    if (!searchPage) return null;
    return (
      <div key={`category-${index}`} className="col">
        <a href={`${buildLinkByPage(searchPage)}?cat=${item.id}`} data-role="none" title={translate(item.translation_key)}
           rel="external">{translate(item.translation_key)}</a>
      </div>
    )
  };

  return (
    <section className="mamcom_component category_link_list_container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <header className="container_header mb-4">
            <div className="heading">
              <h3 dangerouslySetInnerHTML={{__html: title ?? translate('mam.blocks.partner.categories.title')}} />
            </div>
            <div className="heading">
              <h3 dangerouslySetInnerHTML={{ __html: subTitle ?? translate("mam.blocks.partner.categories.sub_title") }} />
            </div>
          </header>
          {searchPage && (
            <div className="row row-cols-1 row-cols-lg-4 g-4">
              <div className="col">
                <a href={buildLinkByPage(searchPage)} data-role="none"
                   title={translate('mam.blocks.partner.categories.all_shops')} rel="external">
                  {translate('mam.blocks.partner.categories.all_shops')}
                </a>
              </div>
              {data?.data?.map(renderCategory)}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}