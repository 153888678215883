import { PageListItem } from "../index";
import { useTranslation } from "../../context/translation";
import dayjs from "dayjs";
import { useDateFormatter } from "../../utils/date";
import { useState } from "react";
import { useLinkBuilder } from "../../utils/link";


type PartnerChipMenuProps = {
  title?: string;
  allShops?: boolean;
  allShopsPage?: PageListItem;
};

export type DirectSelectEvent = {
  type: string;
};

export default function PartnerChipMenu({title, allShops = true, allShopsPage}: PartnerChipMenuProps) {
  const {translate} = useTranslation();
  const {getReadableMonth} = useDateFormatter();
  const {buildLinkByPage} = useLinkBuilder();

  const [selectedType, setSelectedType] = useState<string>('all');

  const directSelect = (type: string) => {
    document.dispatchEvent(new CustomEvent<DirectSelectEvent>('campaignSelect', {detail: {type}}));
    setSelectedType(type);
  };

  const now = dayjs().format('MM-YYYY')

  return (
    <section className="mamcom_component mam_chip_menu_container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <header className="container_header">
            <div className="heading"><h3 dangerouslySetInnerHTML={{__html: title ?? translate('mam.blocks.partner.chip_menu.title')}} /></div>
            {(allShops && allShopsPage) && (
              <a href={buildLinkByPage(allShopsPage)} title={translate('mam.blocks.partner.chip_menu.all_shops')} className="link_to_page" data-role="none" rel="internal">
                <span className="link_text_to_page" data-role="none">{translate('mam.blocks.partner.chip_menu.all_shops')}</span>
                <span className="mam-ico26"></span>
              </a>
            )}
          </header>
          <div className="mam-select-chips">
            <div className={`chip ${selectedType === `all` ? `chip--active` : ``}`}
                 onClick={() => directSelect(`all`)}
                 data-role="none" data-value={`all`}>
              <div className="text">{translate('mam.blocks.partner.chip_menu.all_shops')}</div>
            </div>
            <div className={`chip ${selectedType === `recommendations-${now}` ? `chip--active` : ``}`}
                 onClick={() => directSelect(`recommendations-${now}`)}
                 data-role="none" data-value={`recommendations-${now}`}>
              <div className="text">{translate('mam.blocks.partner.chip_menu.recommendations')}</div>
            </div>
            <div className={`chip ${selectedType === `campaign-${now}` ? `chip--active` : ``}`}
                 onClick={() => directSelect(`campaign-${now}`)} data-role="none"
                 data-value={`campaign-${now}`}>
              <div
                className="text">{translate('mam.blocks.partner.chip_menu.month_campaign', getReadableMonth(dayjs().toISOString()))}</div>
            </div>
            <div className={`chip ${selectedType === `sale` ? `chip--active` : ``}`}
                 onClick={() => directSelect('sale')} data-role="none"
                 data-value="sale">
              <div className="text">{translate('mam.blocks.partner.chip_menu.voucher_and_sale')}</div>
            </div>
            <div className={`chip ${selectedType === `new` ? `chip--active` : ``}`} onClick={() => directSelect('new')}
                 data-role="none"
                 data-value="new">
              <div className="text">{translate('mam.blocks.partner.chip_menu.new_shops')}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}