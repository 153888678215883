import React from "react";
import { RouteObject } from "react-router";


// render - dashboard
import PreviewPage from "./../components/PreviewPage";

// ==============================|| MAIN ROUTING ||============================== //

const PreviewRoutes: RouteObject = {
  path: '_preview/:locale',
  index: false,
  element: <PreviewPage />,
  caseSensitive: false,
};

export default PreviewRoutes;
