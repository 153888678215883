import React from "react";
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes";

import 'simplebar/src/simplebar.css';


// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

function init() {
  const container = document.getElementById('root')!;
  const root = createRoot(container);
  const router = createBrowserRouter(routes)
  root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
}

init();

