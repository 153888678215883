import React from "react";
import _ from "lodash";
import { PageListItem } from "./index";

type DataBindingProps = {
  text: string;
  data: any;
};

type LocalizedTextEntry = {
  locale: string;
  content: string;
}

type LocalizedTextArray = Array<LocalizedTextEntry>;

const searchValue = (variable: string, data: any) => {
  let value;
  const field = variable?.substring(1, variable.length - 1);
  const vars = field.split('.');
  for (const col of vars) {
    if (value) {
      value = value[col];
    } else {
      if (data[col] || data[_.snakeCase(col)]) {
        value = data[col] ?? data[_.snakeCase(col)];
      } else {
        value = data?.bonded_data?.[col] ?? null;
      }
    }
  }
  return value ?? variable;
};

const splitWords = (text: string) => {
  let results = [];
  const dom = new DOMParser();
  const doc = dom.parseFromString(text, 'text/html');

  // @ts-ignore
  for (const docElement of doc.getElementsByTagName('p')) {
    const words = docElement.innerHTML.match(/"[^"]+"|[^"\s]+/g) ?? [];
    for (const word of words) {
      let start, end;
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        if (char === '{') {
          start = i;
        }
        if (char === '}') {
          end = i + 1;
        }
      }
      if (typeof start !== "undefined" && typeof end !== "undefined") {
        results.push(word.substring(start, end));
      }
    }
  }

  if (results.length === 0) {
    const regex = new RegExp(/\{.*\}/g)
    const match = text.match(regex);
    if (match) {
      results.push(match[0]);
    }
  }
  return results;
};

export const searchAndReplace = (text: string, data: any) => {
  let result = text;
  if (data) {
    const vars = splitWords(text);
    for (const word of vars) {
      let value = searchValue(word, data);
      if (value instanceof Array && data?.locale) {
        for (const valueElement of value) {
          if (valueElement?.locale === data.locale.substring(0, 2)) {
            value = valueElement.content;
            break;
          }
        }
      }
      if (typeof value !== "object") {
        result = result.replace(word, value);
      }
    }
  }
  return result;
};

export const getLocaleText = (value: LocalizedTextArray, data?: PageListItem) => {
  for (const localizedTextEntry of value) {
    if (localizedTextEntry.locale === data?.locale.substring(0, 2)) {
      return localizedTextEntry.content;
    }
  }
  return ''
}

export default function DataBinding({text, data}: DataBindingProps) {
  const html = searchAndReplace(text, data);
  return <div dangerouslySetInnerHTML={{__html: html }} />;
}


export const RenderAllIcons = () => {
  return Array.from(Array(158).keys()).map((key) => {
    return (
      <div key={key}>
        <div>
          <div>{key}</div>
          <span className={`mam-ico${key}`} />
        </div>
        <br />
      </div>
    )
  });
};