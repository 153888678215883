
type ConfigType = {
    apiHost: string;
    baseTitle: string;
    defaultPath: string;
    container: boolean;
    mode: 'light' | 'dark';
    presetColor: string;
    themeDirection: 'ltr' | 'rtl';
    clientId: string;
    secret: string;
};

const config: ConfigType = {
    apiHost: 'https://api2.prd.mmi.q-two-hosting.com',
    // apiHost: 'https://localhost',
    baseTitle: 'Miles & More Onlineshopping',
    defaultPath: '/',
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr',
    // CLIENT-ID MUST BE UPDATED MANUALLY AFTER DEPLOY
    clientId: 'd5d4ef2daf4b9fd9e604326827a4d729',
    secret: '918af6f420b5d92554f8ebf86a06e0b892a2b82918ca99766a23aae50abbd80bd1780f7b1a1cf3a550b26f9697e5be1caf28aedb3d42151c0b47dba3d5d00df2',

    // clientId: '912b5760a4037414290361976ccc914f',
    // secret: '6699fc2db23ea3edd1931f94896fd8a741aa1a55ea1392bf10a9433e75c65ba12b1a72932108c9552079ab99344ab5bde4cb0c227d5093626d2779ba4d53aad8',
};

export default config;
