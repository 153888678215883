import { PageListItem } from "../components";
import { useNavigate } from "react-router-dom";
import { MouseEvent } from "react";


export const useLinkBuilder = () => {
  const navigate = useNavigate();

  const buildLinkByPage = (page: PageListItem) => {

    if (page.bind_value) {
      return page.slug.replace(/\{.*\}/, page.bind_value);
    }
    return page.slug;
  };

  const redirectToPage = (page?: PageListItem, event?: MouseEvent, queryString?: string) => {
    event?.preventDefault();
    if (!page) {
      return;
    }
    navigate(`${buildLinkByPage(page)}${queryString ? queryString : ``}`);
  }

  return {
    buildLinkByPage,
    redirectToPage,
  };
};