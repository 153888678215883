import React, { useEffect, useState } from 'react';

type BlurryLoadingImageProps = {
 preview: string;
 image: string;
 alt?: string;
 imageStyleClass?: string
 divStyleClass?: string;
 bgColor?: string;
 minHeight?: number | string;
};

const BlurryLoadingImage = ({
                              preview,
                              image,
                              alt,
                              imageStyleClass,
                              divStyleClass,
                              bgColor = 'transparent',
                              minHeight
                            }: BlurryLoadingImageProps) => {
  const [currentImage, setCurrentImage] = useState(preview);
  const [loading, setLoading] = useState(true);

  const fetchImage = (src: string) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(image);
  }, [image]);

  return (
    <div className={divStyleClass} style={{ overflow: 'hidden' }}>
      <img
        style={{
          filter: `${loading ? 'blur(6px)' : ''}`,
          transition: '150ms filter linear',
          background: bgColor,
          minHeight,
        }}
        src={currentImage}
        alt={alt}
        className={imageStyleClass}
      />
    </div>
  );
};

export default BlurryLoadingImage;