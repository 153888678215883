import { useMemo } from 'react';
import dayjs from "dayjs";

export function useDateFormatter() {
  const i18n = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;

  return useMemo(() => {
    const formatDate = (
      dateTime: string,
      withClock = false,
      clockWithSeconds = false,
      suffix: string = '',
    ) => {
      const locale = i18n.substring(0, 2);

      let format = 'D.M.YYYY'
      switch (locale) {
        case 'de':
          format = 'DD.MM.YYYY';
          break;
      }

      return `${dayjs(dateTime).locale(locale).format(format).toString()} ${suffix}`;
    };

    const getReadableMonth = (
      dateTime: string
    ) => {
      const date = new Date(dateTime);

      return `${date.toLocaleString(i18n, {
        month: "long",
      })}`;
    };

    return {
      formatDate,
      getReadableMonth
    };
  }, [i18n]);
}
