import { useApi } from "../api";
import { useMemo, useState } from "react";
import { ParsedContent } from "../components";

export type MappingType = {
  api: string;
  call: string;
  args: Array<any>;
};

type SelectedApiType = ((pagination: boolean, search: boolean) => void) | null;

export const useDataQuery = () => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  const onLoad = () => {
    setLoading(true);
  };

  const onLoadEnd = () => {
    setLoading(false);
  };

  const getSelectedApi = (mapping: MappingType): SelectedApiType => {
    if (!api[mapping.api] || !api[mapping.api][mapping.call]) {
      return null;
    }

    const apiCall = api[mapping.api][mapping.call];
    return (pagination: boolean, search: boolean = false) => {
      onLoad();
      apiCall(...mapping.args)
        .then((response: any) => {
          if (response && (!response.error || response?.status < 400)) {
            if (pagination) {
              if (response.offset === 0) {
                setData(response);
              } else {
                if (search) {
                  setData((draft: any) => {
                    const draftCopy = Object.assign({}, {...draft, ...response});
                    for (const key of Object.keys(draft.results)) {
                      if (response?.results?.[key]) {
                        draftCopy.results[key] = [...draft?.results?.[key] ?? [], ...response.results[key]];
                      }
                    }
                    return draftCopy;
                  });
                } else {
                  setData({...response, data: [...data.data, ...response.data]});
                }
              }
            } else {
              setData(response);
            }
          }
          onLoadEnd();
        })
        .catch(onLoadEnd);
    }
  };

  const runQuery = (mapping: MappingType, pagination: boolean = false, search: boolean = false): boolean => {
    const selectedApi = getSelectedApi(mapping);
    if (selectedApi) {
      selectedApi(pagination, search);
      return true;
    }
    return false;
  };

  const runQueryAsync = (mapping: MappingType): Promise<any> => {
    const apiCall = api[mapping.api][mapping.call];
    return apiCall(...mapping.args);
  };

  const buildArgs = (mapping: ParsedContent['mapping']): Array<any> => {
    return mapping?.args?.map(arg => arg.argument) ?? [];
  };

  return useMemo(() => {
    return {
      initialized: api.authenticated,
      loading,
      data,
      runQuery,
      runQueryAsync,
      buildArgs,
    }
  }, [api, loading, data]);
};
