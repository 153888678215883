import React, { useEffect } from "react";
import { PageItem } from "../index";

export type VisitedItem = {
  id: string;
  uniqueName: string;
  name: string;
  attachments: Array<any>;
};


type TrackLastVisitedProps = {
  page: PageItem;
};

export default function TrackLastVisited({page}: TrackLastVisitedProps) {
  const getArrayKey = (id: string, array: Array<VisitedItem>): number | null => {
    const result = array.find(item => item.id === id);
    if (result) {
      return array.indexOf(result);
    }
    return null;
  };

  const arrayMove = (arr: Array<any>, oldIndex: number, newIndex: number) => {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  };

  useEffect(() => {
    let data = [];
    const strData = localStorage.getItem('mam:last-visited');
    if (strData) {
      data = JSON.parse(strData) ?? [];
    }
    if (page?.bonded_data && page.bonded_data.id) {
      const foundKey = getArrayKey(page.bonded_data.id, data);
      if (foundKey !== null) {
        data = arrayMove(data, foundKey, data.length - 1);
      } else {
        data.push({
          id: page.bonded_data.id,
          uniqueName: page.bonded_data.unique_name,
          name: page.bonded_data.name,
          attachments: page.bonded_data.attachments,
        });
      }
    }
    localStorage.setItem('mam:last-visited', JSON.stringify(data));
  }, []);

  return null;
}