import React, { useEffect, useState } from "react";
import { useTranslation } from "../../context/translation";
import { VisitedItem } from "./TrackLastVisited";
import { useImageBuilder } from "../../utils/image";
import { PageListItem } from "../index";
import _ from "lodash";

type LastVisitedProps = {
  title?: string;
  partnerPage?: PageListItem;
  allShops?: boolean;
  allShopsPage?: PageListItem;
}

export const getUrlVarAndValue = (slug: string, item: any) => {
  const match = slug.match('{(.*)}');
  if (match && (item[match[1]] || item[_.snakeCase(match[1])])) {
    return {variable: match[0], value: item[match[1]] ?? item[_.snakeCase(match[1])]};
  }
  return null;
};

export default function LastVisited({title, partnerPage, allShops = true, allShopsPage}: LastVisitedProps) {
  const {translate} = useTranslation();
  const { buildFromAttachments } = useImageBuilder();

  const [lastVisited, setLastVisited] = useState<Array<VisitedItem>>([]);


  useEffect(() => {
    const strData = localStorage.getItem('mam:last-visited');
    if (strData) {
      setLastVisited(JSON.parse(strData));
    }
  }, []);


  const renderLastVisited = (item: VisitedItem, index: number) => {
    const urlParams = getUrlVarAndValue(partnerPage?.slug ?? '', item);
    let url = "";
    if (urlParams) {
      url = partnerPage?.slug.replace(urlParams.variable, urlParams.value) ?? '';
    }
    return (
      <div key={`last-visited-${index}`} className="partner_card col">
        <a className="partner_card_link" href={url} rel="internal" tabIndex={0}>
          <div className="partner_card_logo_alignment">
            <div className="image_container">
              <img src={buildFromAttachments(item.attachments, 'Logo')} alt={`Logo ${item.name}`} />
            </div>
          </div>
        </a>
      </div>
    );
  };

  if (!lastVisited || lastVisited.length === 0) {
    return null;
  }

  return (
    <section className="mamcom_component mam_partner_card_list_container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <header className="container_header">
            <div className="heading"><h3 dangerouslySetInnerHTML={{__html: title ?? translate('mam.blocks.partner.last_visited.title')}} /></div>
            {(allShops && allShopsPage) && (
              <a href={allShopsPage.slug} title={translate('mam.blocks.partner.last_visited.all_shops')} className="link_to_page" data-role="none"
                 rel="internal">
                <span className="link_text_to_page" data-role="none">{translate('mam.blocks.partner.last_visited.all_shops')}</span>
                <span className="mam-ico26"></span>
              </a>
            )}
          </header>
        </div>
        <div className="col-sm-10 offset-sm-1 row-cols-2 row-cols-sm-3 row-cols-md-5">
          {lastVisited.reverse().slice(0, 5).map(renderLastVisited)}
        </div>
      </div>
    </section>
  )
}