type LocalTranslationType = { [i18n: string]: { [key: string]: string } };

const localTranslation: LocalTranslationType = {
    de: {
        // General translations
        'errors.no_connection': 'Bitte überprüfen Sie Ihre Netzwerkverbindung!',
        'errors.retry': 'Erneut versuchen',
        'errors.not_found': 'Seite nicht gefunden',

        'static.settings.lang.en': 'Englisch',
        'static.settings.lang.de': 'Deutsch',
        'static.settings.lang.fr': 'Französisch',

        // General form translations
        'form.general.back': 'Zurück',
        'form.general.not_blank': 'Dieses Feld sollte nicht leer sein',
        'form.general.no_email':
            'Es wurde keine gültige E-Mail Adresse erkannt',

    },
    en: {
        // General translations
        'errors.no_connection': 'Please check your network connection!',
        'errors.retry': 'Retry',
        'errors.not_found': 'Page not found',
        'static.settings.lang.en': 'English',
        'static.settings.lang.de': 'German',
        'static.settings.lang.fr': 'Francais',

        // General form translations
        'form.general.back': 'Back',
        'form.general.not_blank': 'This field should not be blank',
        'form.general.no_email': 'No valid email was recognized',
    },
};

export default localTranslation;
