import { ApiClass } from "./types";

type FilterVars = {
  [key: string]: any;
}

export type PartnerLinkResponse = {
  [id: string]: string;
}

export type Partner = {
  getPartnerList: (limit: number, offset: number) => Promise<any>;
  getPartnerLinks: (partnerId: string, trackingProps: {[key: string]: any}) => Promise<PartnerLinkResponse>;
  getCategoryList: (limit: number, offset: number) => Promise<any>;
  searchPartner: (query: string, limit: number, offset: number, filter?: FilterVars) => Promise<any>;
};

const parseFilter = (filter: FilterVars): string => {
  let result = "";
  for (const key of Object.keys(filter)) {
    const value = filter[key];
    result += `&filter[${key}]=${value}`;
  }
  return result;
};

const partner = (
  apiHost: string,
  token: string | null,
  onNetworkError: () => void,
  locale?: string
): ApiClass<Partner> => ({
  getPartnerList: async (limit = 15, offset = 0, sort?: string, order?: string, filter?: FilterVars) => {
    try {
      const headers: any = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      if (locale) {
        headers["Accept-Language"] = locale;
      }
      const response = await fetch(new URL(`/api/partner/list?limit=${limit}&offset=${offset}${(sort && order) ? `&sort=${sort}&order=${order}` : ``}${filter ? parseFilter(filter) : ''}`, apiHost), {
        method: "GET",
        headers: headers,
        mode: "cors"
      });

      return response.json();
    } catch (e) {
      onNetworkError();
      return null;
    }
  },
  getPartnerLinks: async (partnerId: string, trackingProps: {[key: string]: any}) => {
    try {
      const headers: any = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      if (locale) {
        headers["Accept-Language"] = locale;
      }
      const response = await fetch(new URL(`/api/partner/link/${partnerId}`, apiHost), {
        method: "POST",
        headers: headers,
        body: JSON.stringify(trackingProps),
        mode: "cors"
      });

      return response.json();
    } catch (e) {
      onNetworkError();
      return null;
    }
  },
  getCategoryList: async (limit = 15, offset = 0, sort?: string, order?: string) => {
    try {
      const headers: any = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      if (locale) {
        headers["Accept-Language"] = locale;
      }
      const response = await fetch(new URL(`/api/category/list?limit=${limit}&offset=${offset}${(sort && order) ? `&sort=${sort}&order=${order}` : ``}`, apiHost), {
        method: "GET",
        headers,
        mode: "cors"
      });

      return response.json();
    } catch (e) {
      onNetworkError();
      return null;
    }
  },
  searchPartner: async (query?: string, limit = 15, offset = 0, filter?: FilterVars) => {
    try {
      const headers: any = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      if (locale) {
        headers["Accept-Language"] = locale;
      }
      const response = await fetch(new URL(`/api/search${query ? `/${query}` : ``}?type=partner&limit=${limit}&offset=${offset}${filter ? parseFilter(filter) : ''}`, apiHost), {
        method: "GET",
        headers,
        mode: "cors"
      });

      return response.json();
    } catch (e) {
      onNetworkError();
      return null;
    }
  },
});

export default partner;
