import { useDataQuery } from "../../utils/data-query";
import { useEffect, useState } from "react";
import { useTranslation } from "../../context/translation";
import { useDidMountEffect } from "../../utils/mount";

type CategorySelectorProps = {
  onCategoryChange: (categoryIdentifier: string | undefined) => void;
  parentSelectedCategory?: string;
}

export type CategorySelectEvent = {
  category?: string;
}

export default function CategorySelector({
                                           onCategoryChange = () => {
                                           }, parentSelectedCategory,
                                         }: CategorySelectorProps) {
  const { translate } = useTranslation();
  const { initialized, loading, data, runQuery } = useDataQuery();

  const [selectedCategory, setSelectedCategory] = useState<string>();

  useEffect(() => {
    if (!loading && initialized && !data) {
      runQuery({
        api: "partner",
        call: "getCategoryList",
        args: [1000, 0, "translationKey", "asc"]
      }, true);
    }
  }, [initialized]);

  useDidMountEffect(() => {
    if (parentSelectedCategory !== selectedCategory) {
      onCategoryChange(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (parentSelectedCategory && parentSelectedCategory !== selectedCategory) {
      setSelectedCategory(parentSelectedCategory);
    }
  }, [parentSelectedCategory]);

  const renderCategory = (item: any, index: number) => {
    return (
      <div key={`category-${index}`} className={`chip ${selectedCategory === item.id ? "chip--active" : ""}`}
           onClick={() => setSelectedCategory(item.id)} data-role="none" data-value="...">
        <div className="text">{translate(item.translation_key)}</div>
      </div>
    );
  };

  return (
    <div className="mam-select-chips">
      <div className="subheading"><h4>{translate("mam.blocks.partner.search.category_title")}</h4></div>
      <div key={`category-reset`} className={`chip ${selectedCategory === undefined ? "chip--active" : ""}`}
           onClick={() => setSelectedCategory(undefined)} data-role="none" data-value="...">
        <div className="text">{translate("mam.category.all")}</div>
      </div>
      {data?.data?.map(renderCategory)}
    </div>
  );
}