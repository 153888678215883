import React from "react";
import { RouteObject } from "react-router";

// render - dashboard
import MainPage from './../components/MainPage';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
    path: '*',
    index: true,
    element: <MainPage />,
    caseSensitive: false,
};

export default MainRoutes;
