import config from '../config';

const defaultScopes = ['partner_read', 'network_read', 'cms_read',];

const auth = (
    apiHost: string,
    token: string | null,
    onNetworkError: () => void,
) => ({
    login: async (username: string, password: string) => {
        try {
            const params = new URLSearchParams({
                grant_type: 'password',
                client_id: config.clientId,
                username,
                password,
            });

            const response = await fetch(new URL('/token', apiHost), {
                method: 'POST',
                body: params.toString(), // FIXME i think the toString is useless here. Typescript doesn't complain
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                mode: 'cors',
            });

            return response.json();
        } catch (e) {
            onNetworkError();
            return null;
        }
    },
    authenticate: async () => {
        try {
            const params = new URLSearchParams({
                grant_type: 'client_credentials',
                client_id: config.clientId,
                client_secret: config.secret,
                scopes: defaultScopes.join(" "),
            });

            const response = await fetch(new URL('/token', apiHost), {
                method: 'POST',
                body: params.toString(), // FIXME i think the toString is useless here. Typescript doesn't complain
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                mode: 'cors',
            });

            return response.json();
        } catch (e) {
            onNetworkError();
            return null;
        }
    },
});

export default auth;
