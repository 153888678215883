import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "../../context/translation";
import { useNavigate } from "react-router-dom";
import { useDataQuery } from "../../utils/data-query";
import CategorySelector from "./CategorySelector";
import { PageListItem } from "../index";
import { useDidMountEffect } from "../../utils/mount";
import { searchAndReplace } from "../DataBinding";
import { iniSearchField } from "../../utils/jquery";

type PartnerSearchProps = {
  baseQuery?: string;
  title?: string;
  showCategories?: boolean;
  parentSelectedCategory?: string;
  searchPage?: PageListItem;
  partnerPage?: PageListItem;
  onQueryChange?: (query: string) => void;
  disableTypeAHead?: boolean;
};

export default function PartnerSearch({
                                        title,
                                        baseQuery = "",
                                        showCategories = false,
                                        parentSelectedCategory,
                                        searchPage,
                                        partnerPage,
                                        onQueryChange = () => {
                                        },
                                        disableTypeAHead = false
                                      }: PartnerSearchProps) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { initialized, loading, data, runQuery } = useDataQuery();

  const [typeAHeadEnabled, setTypeAHeadEnabled] = useState(false);

  const [searchQuery, setSearchQuery] = useState(baseQuery);
  const [selectedCategory, setSelectedCategory] = useState<string>();

  useEffect(() => {
    iniSearchField();
  }, []);

  useEffect(() => {
    if (parentSelectedCategory && !selectedCategory) {
      setSelectedCategory(parentSelectedCategory);
    }
  }, [parentSelectedCategory]);

  useEffect(() => {
    if (initialized && typeAHeadEnabled && !loading && searchQuery && searchQuery.length > 2 && !disableTypeAHead) {
      runQuery({
        api: "partner",
        call: "searchPartner",
        args: [searchQuery, 1000]
      }, true);
    }
  }, [initialized, typeAHeadEnabled, searchQuery]);

  useDidMountEffect(() => {
    onQueryChange(searchQuery);
  }, [searchQuery]);

  const updateQuery = (event: ChangeEvent) => {
    // @ts-ignore
    setSearchQuery(event.target.value);
  };

  const executeSearch = (event: FormEvent) => {
    event.preventDefault();
    const params: any = {};
    if (searchQuery) {
      params["query"] = searchQuery;
    }
    if (selectedCategory) {
      params["cat"] = selectedCategory;
    }
    const urlParams = new URLSearchParams(params);
    navigate(`${searchPage?.slug}?${urlParams.toString()}`);
    return;
  };

  const openTypeAHead = () => {
    setTypeAHeadEnabled(true);
  };

  const closeTypeAHead = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => setTypeAHeadEnabled(false), 250);
  };

  const renderTypeAHead = (item: any, index: number) => {
    const lower = item.item_name.toLowerCase();
    const start = lower.search(searchQuery?.toLowerCase());

    let formatedResult = item.item_name;
    if (start >= 0) {
      formatedResult = `${item.item_name.substring(0, start)}<span class="text-info">${item.item_name.substring(start, start + searchQuery.length)}</span>${item.item_name.substring(start + searchQuery.length)}`;
    }
    let url = "";
    if (partnerPage) {
      url = searchAndReplace(partnerPage.slug, item);
    }
    return (
      <a key={`type-a-head-${index}`} className="autocomplete-result list-group-item p-1" href={url}>
        <span>
          <div className="partner_search" rel="internal">
            <span className="mam-ico28"></span>
            <span dangerouslySetInnerHTML={{ __html: formatedResult }}></span>
          </div>
        </span>
      </a>
    );
  };

  const navigateToSearchPage = (category?: string) => {
    if (searchPage) {
      const params: any = {};
      if (searchQuery) {
        params["query"] = searchQuery;
      }
      if (category) {
        params["cat"] = category;
      }
      const urlParams = new URLSearchParams(params);
      navigate(`${searchPage.slug}?${urlParams.toString()}`);
      return;
    }
  };

  const typeAHeadItems = (searchQuery.length > 2 && data?.results?.partner) ? data?.results?.partner : [];

  return (
    <section className="mamcom_component mam_search_container overflow-visible">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <header className="search_header">
            <div className="heading"><h3
              dangerouslySetInnerHTML={{ __html: title ?? translate("mam.blocks.partner.search.title") }} /></div>
          </header>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <div className="search_form_wrapper">
            <form className="form_elements" onSubmit={executeSearch}>
              <div className="form_element_wrapper">
                <div className="searchtrigger_input inputfield_1">
                  <div className="form_textfield">
                    <input
                      type="text"
                      className="form-control search_autocomplete"
                      id="search_input"
                      name="mm_search_partner"
                      inputMode="search"
                      placeholder={translate("mam.blocks.partner.search.placeholder")}
                      autoComplete="off"
                      value={searchQuery}
                      onChange={updateQuery}
                      onFocus={openTypeAHead}
                      onBlur={closeTypeAHead}
                    />
                    {searchQuery?.length > 0 && (
                      <span className="form_textfield_icon_cleaner" onClick={() => setSearchQuery('')}>
                        <span className={`mamcomicon--baseline mam-ico8`}></span>
                      </span>
                    )}
                    <div className={`position-absolute ${typeAHeadEnabled ? "visible" : "invisible"} autocomplete`}>
                      <ul className="list-group mt-1">
                        {typeAHeadItems.map(renderTypeAHead)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <button className="searchtrigger_search button button--filled button--primary" onClick={executeSearch}
                      data-role="none" disabled={searchQuery.length === 0}>
                <span className="button_text">{translate("mam.blocks.partner.search.btn")}</span>
              </button>
            </form>
            {showCategories && (
              <CategorySelector
                onCategoryChange={navigateToSearchPage}
                parentSelectedCategory={parentSelectedCategory}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}