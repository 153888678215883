import React, { EventHandler, useEffect, useState } from "react";
import Components, { ParsedContent, TemplateBlock } from "./index";

import './PreviewPage.css';
import { useParams } from "react-router";
import { useTranslation } from "../context/translation";
import { useNavigate } from "react-router-dom";

export default function PreviewPage() {
  const navigate = useNavigate();
  const {locale} = useParams();

  const {i18n, translate} = useTranslation();
  const [previewParams, setPreviewParams] = useState<any>({});

  const onLoad = () => {
    const thisWindow = window.opener || window.top;
    thisWindow.postMessage('preview:opened', '*');
  };

  const onMessage = (event: MessageEvent) => {
    if (event.data?.previewProps) {
      setPreviewParams(event.data.previewProps);
    }
  };

  const onClose = () => {
    if (!window.navigator.userActivation.isActive) {
      const thisWindow = window.opener || window.top;
      thisWindow.postMessage('preview:closed', '*');
    }
  };

  useEffect(() => {
    window.addEventListener('load', onLoad);
    window.addEventListener('message', onMessage);
    window.addEventListener('beforeunload', onClose);
    return () => {
      window.removeEventListener('load', onLoad);
      window.removeEventListener('message', onMessage);
      window.removeEventListener('beforeunload', onClose);
    }
  }, []);

  const renderBlocks = (block: TemplateBlock<ParsedContent>, index: number) => {
    const BlockComponent = Components[block.block_name];
    if (!BlockComponent) {
      console.warn(block.block_name, 'not implemented');
      return null;
    }
    const props = block.content?.props;
    const mapping = block.content?.mapping;
    return <BlockComponent key={index} {...props} mapping={mapping} preview={true} />
  };

  const goBack = (event: MouseEvent) => {
    //event.preventDefault();
    //navigate(-1);
  };

  return (
    <React.Fragment>
      <main className="container">
        {(previewParams.has_parent) && (
          <section className="mamcom_component mam_backlink_container">
            <div className="row">
              <div className="backlink col-xs-12">
                <a href="/" onClick={goBack as EventHandler<any>} className="backlink_anchor" data-role="none" rel="external">
                  <div className="backlink_iconWrapper">
                    <span className="mam-ico26 "></span>
                  </div>
                  <div className="backlink_text">{translate('mam.static.back')}</div>
                </a>
              </div>
            </div>
          </section>
        )}
        {previewParams?.blocks?.map(renderBlocks)}
      </main>
    </React.Fragment>
  )
}
