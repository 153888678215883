import $ from "jquery";

function isNumberKey(e: HTMLInputElement, ev: KeyboardEvent) {
  var keyCode = window.event ? ev.keyCode : ev.which;
  if (keyCode < 48 || keyCode > 57 || e.value.length >= 21) {
    if (keyCode != 0 && keyCode !=8 && keyCode != 13 && !ev.ctrlKey) ev.preventDefault();
  }
}

function scnInputUnfocus(e: HTMLElement) {
  if ($(e).parent().hasClass("focus") === true) {
    if ($(e).parent().find("input").val() != "") {
      $(e).parent().addClass("filled");
    } else {
      $(e).parent().removeClass("focus");
      $(e).parent().find(".form_textfield_icon_cleaner").first().fadeTo(100, 0);
      $(e).parent().find("input").first().fadeTo(100, 0);
      var bottom = 4;
      if (($(".mamcom_container").outerWidth() ?? 0) > 1279) bottom = 8;
      $(e).parent().find("label").first().stop().animate({
        bottom: bottom + "px",
      }, 100);
    }
  }
}

export function scnInputFocus(e: HTMLElement) {
  if ($(e).parent().hasClass("filled") === true) {
    $(e).parent().removeClass("filled");
  }
  if ($(e).parent().hasClass("focus") !== true) {
    $(e).parent().addClass("focus");
    $(e).parent().find("label").first().stop().animate({
      bottom: "30px",
    }, 100);
    $(e).parent().find(".form_textfield_icon_cleaner").first().fadeTo(100, 1);
    $(e).parent().find("input").first().fadeTo(100, 1);
    $(e).parent().find("input").first().focus();
  }
  $(e).parent().find("input").first().focusout(function() {
    scnInputUnfocus(e);
  });
}

export function initScnOverlay() {
  let scnInput = document.querySelector("input[name=mm_scn]");
  if (scnInput === null) {
    return false;
  }
  $(".servicecard_input .form_textfield_input").bind("click", function() {
    scnInputFocus(this);
  });
  $(".servicecard_input .form_textfield_label").bind("click", function() {
    scnInputFocus(this);
  });
  $(".servicecard_input .form_textfield_input").bind("keypress", function(event) {
    // @ts-ignore
    isNumberKey(this, event)
  });
}

export function iniSearchField() {
  let scnInput = document.querySelector("input[name=mm_search_partner]");
  if (scnInput === null) {
    return false;
  }
  $(".searchtrigger_input .search_input").bind("click", function() {
    scnInputFocus(this);
  });
}