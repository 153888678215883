import React from "react";
import { useTranslation } from "../context/translation";

export default function NotFoundPage() {
  const {translate} = useTranslation();

  return (
    <div>
      <h3>404: {translate('errors.not_found')}</h3>
    </div>
  );
}
