import React from "react";
import DataBinding from "../DataBinding";
import { BlockProps } from "./blocks";

interface TextProps extends BlockProps {
  text?: string;
}

export default function Text({text, page}: TextProps) {
  return (
    <section className="mamcom_component">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          {text && <DataBinding text={text} data={page} />}
        </div>
      </div>
    </section>
  );
}
