import { ApiClass, ResponseType } from "./types";

export type Check = {
  validateRef: (ref: string) => Promise<ResponseType<{valid: boolean}> | null>;
};

const check = (
  apiHost: string,
  token: string | null,
  onNetworkError: () => void,
  locale?: string
): ApiClass<Check> => ({
  validateRef: async (ref: string): Promise<ResponseType<{valid: boolean}> | null> => {
    try {
      const headers: any = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      if (locale) {
        headers["Accept-Language"] = locale;
      }
      const response = await fetch(
        new URL(
          `/api/check/customer/${ref}`,
          apiHost,
        ),
        {
          method: 'GET',
          headers,
          mode: 'cors',
        },
      );
      return response.json();
    } catch (e) {
      onNetworkError();
      return null;
    }
  },
});

export default check;
