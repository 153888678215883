import { useTranslation } from "../../context/translation";
import { useEffect, useLayoutEffect, useRef } from "react";
import $ from "jquery";
import { setCarouselHeight } from "../wrapper/SlickSlider";

import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type HowToProps = {
  title?: string;
}

export default function HowTo({ title }: HowToProps) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { translate } = useTranslation();

  const resizeIt = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    // @ts-ignore
    $(`.carousel`).slick('resize');
    timeoutRef.current = setTimeout(() => setCarouselHeight(), 125);
  };

  useEffect(() => {
    // @ts-ignore
    $(`.carousel`).slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      slidesPerRow: 1,
      rows: 1,
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 9500,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 959,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            slidesPerRow: 1,
            rows: 1,
            dots: true
          }
        }
      ]
    });
    resizeIt();

    return () => {
      const slider = $(`.carousel`);

      try {
        if (slider) {
          // @ts-ignore
          slider.slick("unslick");
        }
      } catch (e) {}
    };

  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', resizeIt);
    return () => {
      window.removeEventListener('resize', resizeIt)
    }
  }, []);


  const openPartnerExitModal = () => {
    document.dispatchEvent(new CustomEvent("openExitModal"));
  };

  return (
    <section className="mamcom_component mam_how_to_container">
      <div className="row">
        <div className="col-xs-12 col-md-8 offset-md-2">
          <header className="container_header mb-4">
            <div className="heading">
              <h3 dangerouslySetInnerHTML={{ __html: title ?? translate("mam.blocks.partner.how_to.title") }} />
            </div>
          </header>
        </div>
        <div className="col-xs-12 col-md-8 offset-md-2">
          <div id="how-to-slider" className="carousel">
            <div className="item mam-slide">
              <div className="how_to_content">
                <div className="how_to_icon">
                  <img src="/images/ico83_shopping.svg" />
                  <img src="/images/ico56_like.svg" />
                </div>
                <div className="how_to_textbox">
                  <p className="headline"><strong>{translate("mam.blocks.partner.how_to.step1.title")}</strong></p>
                  <br />
                  <p className="infotext">{translate("mam.blocks.partner.how_to.step1.text")}</p>
                </div>
              </div>
              <img src="/images/ico2_menu-dots.svg" />
            </div>
            <div className="item mam-slide">
              <div className="how_to_content">
                <div className="how_to_icon">
                  <img src="/images/ico120_credit-card.svg" />
                  <img src="/images/ico49_edit-text.svg" />
                </div>
                <div className="how_to_textbox">
                  <p className="headline"><strong>{translate("mam.blocks.partner.how_to.step2.title")}</strong></p>
                  <br />
                  <p className="infotext">{translate("mam.blocks.partner.how_to.step2.text")}</p>
                </div>
              </div>
              <img src="/images/ico2_menu-dots.svg" />
            </div>
            <div className="item mam-slide">
              <div className="how_to_content">
                <div className="how_to_icon">
                  <img src="/images/ico175_computer.svg" />
                  <img src="/images/ico23_external-link.svg" />
                </div>
                <div className="how_to_textbox">
                  <p className="headline"><strong>{translate("mam.blocks.partner.how_to.step3.title")}</strong></p>
                  <br />
                  <p className="infotext">{translate("mam.blocks.partner.how_to.step3.text")}</p>
                </div>
              </div>
              <img src="/images/ico2_menu-dots.svg" />
            </div>
            <div className="item mam-slide">
              <div className="how_to_content">
                <div className="how_to_icon">
                  <img src="/images/ico145_milage-sign-roman.svg" />
                  <img src="/images/ico66_earn-miles.svg" />
                </div>
                <div className="how_to_textbox">
                  <span
                    className="headline"><strong>{translate("mam.blocks.partner.how_to.step4.title")}</strong></span><br />
                  <p className="infotext">{translate("mam.blocks.partner.how_to.step4.text")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="shop_button">
            <button className="shop_redirect_button button button--filled button--primary"
                    onClick={openPartnerExitModal} data-role="none">
              <span className="button_text">{translate("mam.blocks.partner.how_to.shop_btn")}</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}