import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDataQuery } from "../utils/data-query";
import PartnerSearch from "./content-blocks/PartnerSearch";
import PartnerList from "./content-blocks/PartnerList";
import { useTranslation } from "../context/translation";
import { PageListItem } from "./index";
import { useLocation } from "react-router-dom";
import { updateFrameSize, usePrevious } from "../utils/static";

const LOAD_LIMIT = 15;

type SearchResultPageProps = {
  searchPage?: PageListItem;
  partnerPage?: PageListItem;
  allowFilter?: boolean;
  bestPartner?: boolean;
};

export default function SearchResultPage({searchPage, allowFilter = false, bestPartner = false, partnerPage}: SearchResultPageProps) {
  const { translate } = useTranslation();
  const { initialized, loading, data, runQuery } = useDataQuery();

  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const queryFromUrl = urlParams.get("query");
  const selectedCategory = urlParams.get("cat");

  const prevCategory = usePrevious(selectedCategory);

  const [query, setQuery] = useState<string | null>(queryFromUrl);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (queryFromUrl && query !== queryFromUrl) {
      setQuery(queryFromUrl);
    }
  }, [queryFromUrl]);

  const executeSearch = () => {
    if (initialized && !loading) {
      runQuery({
        api: "partner",
        call: "searchPartner",
        args: [query, LOAD_LIMIT, prevCategory !== selectedCategory ? 0 : offset, selectedCategory ? { categories: [selectedCategory] } : undefined]
      }, true, true);
    }
  };

  useEffect(executeSearch, [query, initialized, offset, selectedCategory]);

  useLayoutEffect(() => {
    updateFrameSize();
  }, [data]);

  return (
    <React.Fragment>
      <section className="mamcomcomponent mam_heading_container">
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <div className="heading_base">
              <div className="heading">
                <header className="heading_wrapper">
                  <div className="heading_content">
                    <h1 className="heading_text small">
                      <strong>{selectedCategory ? translate(`mam.category.${selectedCategory}`) : translate("mam.blocks.partner.search.title1", " ")}</strong><br />
                      {query && translate("mam.blocks.partner.search.title2", query)}
                    </h1>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PartnerSearch
        baseQuery={query ?? ''}
        showCategories={true}
        searchPage={searchPage}
        parentSelectedCategory={selectedCategory ?? undefined}
        onQueryChange={setQuery}
        disableTypeAHead
      />
      <PartnerList
        disableNormalLoad
        parentLoading={loading}
        result={data}
        allowFilter={allowFilter}
        bestPartner={bestPartner}
        onLoadMore={() => setOffset((data?.offset ?? 0) + LOAD_LIMIT)}
        allowLoadMore={data?.has_next}
        partnerPage={partnerPage}
      />
    </React.Fragment>
  );

}